.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.preview-top-logo {
  border-bottom: 1px solid #6E6E6E;
  display: flex;
  align-items: center;
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  justify-content: center;
  padding: 10px 10px 0 10px;
  width: 100%;
  margin: 0 auto;
}

.preview-top-logo button {
  background-color: transparent;
  background: linear-gradient(to right, #8F78F4, #8842CA);
  color: white;
  position: absolute;
  right: 1.2vw;
  top: 0;
  font-size: 14px;
  border-radius: 30px;
  padding: 8px 40px;
  margin: 22.5px 0 10px 0;
  text-wrap: nowrap;
  font-weight: bold;
  justify-self: end;
}

.preview-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 20px;
  margin: 4rem auto 0;
  max-width: 80rem;
  gap: 2rem;
}

.preview-top-section-left {
  display: flex;
  flex-direction: column;
  gap: 1.2rem
}

.dream-count-text {
  color: transparent; 
  background: linear-gradient(to right, #8F78F4, #8842CA);
  background-clip: text;
  font-weight: 900;
  font-family: 'Graphik', sans-serif;
  font-size: 65px;
}

.app-stores {
  display: flex;
  gap: 10px;
  align-items: center;
}

.preview-top-section-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  
}

.preview-top-section-right img {
  width: 400px;
  border-radius: 14px;
  border: 1px solid #393939;
}

.fiction-container {
  color: #FFF;
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;

}

.fiction-tag {
  background-color: #1F1F1F;
  color: #BFBFBF;
  border: 1px solid #7F7F7F;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  width: fit-content;
}

.preview-general-btn {
  background: #783CBB;
  border-radius: 20px;
  padding: 0.5rem 1.7rem;
  margin-top: 0.3rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #FFFFFF;
  cursor: pointer;
}

.preview-mid-section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 20px;
  max-width: 80rem;
  font-family: 'Graphik', sans-serif;
  margin: 0px auto;
  gap: 2rem;
}

.preview-mid-section-1 h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #353535;
}

.preview-mid-section-1 p {
  font-size: 15.5px;
  font-weight: 400;
  color: #353535;
}

.preview-mid-section-1-left {
  width: 60%;
}

.preview-mid-section-1-right {
  background-color: #F6F6F6;
  font-family: 'Graphik', sans-serif;
  padding: 20px 20px;
  border-radius: 7px;
}

.preview-mid-section-1-right span {
  font-weight: 600;
}

.preview-mid-section-2 {
  padding: 60px 20px;
  max-width: 80rem;
  margin: 0 auto;
  font-family: 'Graphik', sans-serif;
}

.preview-mid-section-2-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.carousel-controls {
  display: flex;
  gap: 1rem;
}

.carousel-control-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #5F42E2, #9B42C0) border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.carousel-control-btn.disabled {
  background: linear-gradient(white, white) padding-box, #E5E5E5 border-box;
  cursor: not-allowed;
}

.carousel-control-btn:not(.disabled):hover {
  transform: scale(1.05);
}

.preview-mid-section-2-img-array {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 2rem;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: 2rem;
}

.preview-mid-section-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 0px 20px 60px;
  max-width: 80rem;
  margin: 0 auto;
}

.preview-mid-section-3 .gadget-img img {
  width: 600px;
  
  margin: 10px auto;
}

.preview-mid-section-3-right {

}

.add_title_img {
  width: 200px;
  border: 1px solid #E2E2E2;
  border-radius: 7px;

}

.preview-footer {
  background-color: #0F0F0F;
  color: #FFFFFF;
  width: 100%;
  font-family: 'Graphik', sans-serif;
}

.footer-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 60px 20px 0px;
}

.footer-main {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  padding-bottom: 40px;
  border-bottom: 1px solid #2B2B2B;
}

.footer-brand {
  max-width: 400px;
}

.footer-brand p {
  color: #808080;
  margin: 1.5rem 0;
  font-size: 15px;
  line-height: 1.5;
}

.footer-store-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer-store-buttons img {
  cursor: pointer;
}

.footer-links-group h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #D6CCFF;
}

.footer-links-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links-group ul li {
  margin-bottom: 1rem;
}

.footer-links-group ul li a {
  color: #ABABAB;
  text-decoration: none;
  font-size: 15px;
  transition: color 0.2s;
}

.footer-links-group ul li a:hover {
  color: #FFFFFF;
}

.footer-social h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #D6CCFF;
}

.social-icons {
  display: flex;
  gap: 0.75rem;
}

.social-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6C6C6C63;
  display: flex;
  border: 1px solid #8E8E8E4F;
  align-items: center;
  justify-content: center;
  color: #8E8E8E;
  text-decoration: none;
  transition: background-color 0.2s;
}

.social-icon:hover {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #0F0F0F;
}

.social-icon svg {
  width: 18px;
  height: 18px;
}

.footer-bottom {
  max-width: 80rem;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  color: #808080;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .footer-main {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }

  .footer-brand {
    grid-column: 1 / -1;
    max-width: 100%;
  }


.preview-mid-section-3 .gadget-img img {
  width: 300px;
  
  margin: 10px auto;
}

}

@media (max-width: 768px) {
  .footer-main {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .footer-content {
    padding: 40px 20px 0px;
  }

  .footer-social {
    grid-column: 1 / -1;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .footer-main {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-store-buttons {
    justify-content: center;
  }

  .social-icons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .preview-top-logo {
    padding: 10px 20px 0;
  }

  .preview-top-logo p {
    margin-left: 0;
    text-align: center;
  }
  
  .preview-top-section {
    flex-direction: column-reverse;
    padding: 20px;
    margin: 1rem auto;
  }

  .preview-top-section-left {
    width: 100%;
    margin: 20px auto;
    padding: 0 0px;
  }

  .preview-top-section-left p {
    font-size: 16px;
  }

  .preview-top-logo button {
      right: 1.2vw;
      top: 0;
      font-size: 10px;
      border-radius: 25px;
      padding: 8px 20px;    }
    

  .fiction-tag {
    padding: 0.5rem 0.9rem;
  }

  .dream-count-text {
    font-size: 32px;
  }

  .preview-top-section-right img {
    width: 343px;
    height: 343px;
  }

  .preview-mid-section-1 {
    flex-direction: column;
    padding: 40px 20px;

  }

  .preview-mid-section-1-left {
    width: 100%;
  }

  .preview-mid-section-1-right {
    width: 100%;
    margin: 50px auto;
  }

  .preview-mid-section-2 {
    padding: 20px 20px;
  }

  .preview-mid-section-3 {
    flex-direction: column;
    padding: 40px 20px;
  }

  .preview-mid-section-3 .gadget-img img {
    width: 100%;
    max-width: 392px;
    height: auto;
  }

  .preview-mid-section-3-right {
    width: auto;
  }

  .footer-links-group {
    margin-top: 1rem;
  }
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
