body {
  margin: 0;
  font-family: Axiforma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212 !important;
}

* {
  /* font-family: Axiforma; */
  /* font-family: */
}

/* html {
  overflow: scroll;
  overflow-x: hidden;
} */

::-webkit-scrollbar {
  width: 0;
}

/* background: transparent;
/* Optional: just make scrollbar invisible */
/* } 

/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: #FF0000;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.get-app-btn {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  border-radius: 100px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  /* identical to box height, or 24px */

  text-align: center !important;

  color: #FFFFFF !important;
  padding: 12px 37px !important;
  transition: 5000s all ease-in-out;
}

.get-app-btn.explore-section {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: all 50ms ease-in-out !important;
  position: relative;
  border: #5F42E2 1px solid;
}

.get-app-btn:hover,
.get-app-btn.explore-section:hover {
  background: linear-gradient(86.63deg, #9B42C0 11.49%, #5F42E2 100%);
  color: #ffffffe4 !important;
}

.get-app-btn.explore-section:hover {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  color: #fff !important;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
  text-fill-color: unset;
}



.main-wrapper {
  background: #050606;
  min-height: 100vh;
  padding: 20px 0;
}

.content-img {
  height: calc(100vh - 133px);
  object-position: top;
  object-fit: cover;
}

.app-wrapper {
  background: #1B1B1B;
  border-radius: 14px;
  height: calc(100vh - 133px);
  margin-top: 17px;
  /* margin-bottom: 46px; */
}

.first-section-wrapper {
  border-right: 1px solid #5a5a5a;
  height: 100%;
  width: 100%;
  position: relative;
  /* border-radius: 14px; */
  border-radius: 14px 0 0 14px;

}

.second-section-wrapper {
  /* border-left: 1px solid #2F2F2F; */
  /* height: 100%; */
  height: calc(100vh - 133px);

  width: 100%;
  /* background: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, #1B1B1B 100%);
background-position: bottom; */
  position: relative;
  overflow-y: scroll;

}

.section-content-container {
  height: 100% !important;
  overflow-y: scroll;
  position: relative;

}

.second-section-wrapper:before {
  background: linear-gradient(180deg, rgba(27, 27, 27, 0) 80%, #1B1B1B 100%) !important;
  background-position: bottom !important;
  width: 100%;
}

.first-section-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 20em;
  height: 20em; */
  background: linear-gradient(180deg, rgba(27, 27, 27, 0.78) 0%, #1B1B1B 86.11%);
  border-radius: 15px 0 0 15px;
}


.second-section-wrapper {
  width: 100%;
  height: calc(100vh - 133px);
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  padding-top: 21px;
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.second-section-wrapper::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .second-section-wrapper::-webkit-scrollbar-thumb {
  background: #FF0000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);

} */

/* .second-section-wrapper::before {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0) 80%, #1B1B1B 100%) !important;
  overflow-y: scroll;

}
.section-content-container{
  height: calc(100vh - 133px);
  overflow-y: scroll;

} */

.content-details {
  position: absolute;
  padding: 0 45px 45px 45px !important;
  bottom: 0;
  width: 100%;

}


.content-img-wrapper {
  width: 100%;
  /* height: 100%; */
  height: calc(100vh - 133px);
  border-radius: 15px 0 0 15px;
  overflow: hidden;

}

.content-img {
  object-fit: cover;
  width: 100%;
  height: 100%;


}

.content-name {
  font-weight: 800;
  font-size: 24px;
  line-height: 150%;
  color: #FFFFFF;
}

.content-name__wrapper {
  margin-bottom: 20px;

}

.ctrl-container {
  margin-right: 17px;
}

.content-author {

  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */


  color: #FFFFFF;
}

.content-author-role {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */


  color: #979797;
}

.intent-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: #FFFFFF;
}

.intent-icon {
  margin-right: 5px;
}

.content-intent-btn {
  background: #2F2F2F;
  border-radius: 16px;
  padding: 3px 12px;
  margin-right: 10px;
}

.content-intent-btn:last-child {
  margin-right: 0px;

}

.content-select-wrapper {

  margin-right: 10px;
  padding: 2px 12px 2px 0;
  background-color: #fff !important;
  border-radius: 16px;
}

.content-select-wrapper select,
.content-select-wrapper select:focus {
  /* background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%); */
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  background-clip: text;
  text-fill-color: transparent;
  padding: 0 12px 0 12px;
  color: #9B42C0;
  border: none;
  box-shadow: none !important;
  appearance: none;
  outline: none;
  background: url('./assets/img/select.svg') no-repeat right transparent;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  cursor: pointer;
}

.select-icon {
  margin-left: 8px;
}

.content-ctrl-center {
  /* margin-bottom: 43px; */
}

wave wave {
  border-right: 3px solid #5279FB !important;
  position: relative;
}

/* wave wave:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  width: 100%;
  border-top: 3px solid #1B1B1B;
  border-bottom: 3px solid #1B1B1B;
  padding: 10px;
  z-index: 99;
} */

.waveform-wrapper {
  height: 64px;
  overflow: hidden;
  position: relative;

}

#waveform {
  height: 100% !important;
}

.waveform-wrapper cursor {
  width: 12px !important;
  height: 15px !important;
  background: url('./assets/img/cursor.svg') !important;
  background-repeat: no-repeat;
  border-right: unset !important;
  top: 16px !important;
}

wave wave svg:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  bottom: 35%;
  width: 100%;
  border-top: 3px solid #1B1B1B;
  border-bottom: 3px solid #1B1B1B;
  padding: 12px;
  z-index: 10;
}

wave wave svg:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  width: 100%;
  border-top: 3px solid #1B1B1B;
  border-bottom: 3px solid #1B1B1B;
  padding: 12px;
  z-index: 10;
}

.content-length {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;

  color: #FFFFFF;
}

.advert-center {
  padding: 0 45px 0px 45px !important;
  bottom: -25px;

}

.advert-center-container {
  background-image: url('./assets/img/bg.png'), url('./assets/img/bg2.png'), linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  background-repeat: no-repeat;
  background-position: left, right, center;
  border-radius: 5px;
}

.advert-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 132.5%;
  /* or 17px */


  color: #FFFFFF;
}

.advert-btn {
  background: #FFFFFF !important;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.46) !important;
  border-radius: 42px !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 132.5% !important;
  /* identical to box height, or 15px */


  color: #6638B4 !important;
  padding: 8px 28px !important;
}

.advert-img {
  margin-top: -35px;
}

.section-content-container {
  padding: 0px 21px 0 21px;
}

.section-title-wrapper {
  padding: 12px 25px;
  background: #2F2F2F;
  border-radius: 3px;
  margin-bottom: 22px;
}

.section-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */


  color: #FFFFFF;
}

.section-icon {
  margin-right: 12px;
}

.episode-img-container {
  width: 80px;

  height: 63px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #2F2F2F;

}

.episode-img-container img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 6px;


}

.episode-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #FFFFFF
}

.episode-content-count {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */


  color: #979797;
}

.time-icon {
  margin-right: 4px;
}

.episode-list-wrap {
  margin-right: 9.33px;
  cursor: pointer;
  margin-left: 3px;
}

.episode-list-wrap.active {
  margin-left: 0px;

}

.episode-part,
.episode-length {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  color: #979797;
}

.episode-part.active,
.episode-length.active {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  /* animation: scrolling 10s linear infinite; */

}

.unlike {
  background-color: #fff !important;
  color: #000 !important;
}

.unlike .intent-name {
  color: #000 !important;

}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translatex(-1000px);
  }
}

.episodes-container {
  border-bottom: 1px solid #2F2F2F;
}

.episodes-container.explore-player__episodes-container {
  border-bottom: none !important;

}

.recommended-title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */
  color: #FFFFFF;
}

.episode-img-container.empty {
  /* width: 108px; */
  width: 80px;

  height: 63px;
  /* background: #FFFFFF; */
  border: 1px solid #2F2F2F;
  border-radius: 6px;
}

.recommended-listen {
  /* height: 30px; */
  overflow-y: scroll;
}

.scroll-container {
  bottom: 0;
  background: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, #1B1B1B 100%);
  border-radius: 0px 0px 14px 0px;

}


.modal.fade.show {
  background: rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(2px) !important;
}

.modal {
  display: flex;
  justify-content: center;
}

.download_modal_content,
.subscribe_modal_content {
  background-image: url('./assets/img/mdbg.png'), linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  background-repeat: no-repeat;
  background-position: left 100%, center;
  box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.25) !important;
  border-radius: 17.2157px !important;
  width: 100%;
  height: 250.2px;
  width: 398px !important;
  margin-left: auto;
  margin: auto;
  padding: 4px 0 26px 28px;
  border: 2px solid #2F2F2F !important;
}

.subscribe_modal_content {
  width: unset !important;
  background: url('./assets/img/mdbg.png') rgba(225, 225, 225, 0.87) no-repeat left 100% !important;
  background-size: cover;
  background-position: left, center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(33.5px);
  border-radius: 17.2157px;
  height: 284.2px;
  padding: 45px;
}

.download_modal {
  padding-top: 135px;
}

.subscribe_modal {
  padding-top: 105px;

}

.subscribe_modal_content .modal-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 143%;
  /* or 29px */


  color: #FFFFFF;
  margin-bottom: 10px !important;
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 60%;
  margin: auto;
}

.subscribe_modal_content .modal-sub_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */


  color: #FFFFFF;
  /* width: 50%; */
  margin-bottom: 33px;
  line-height: 150%;
  /* or 20px */

  text-align: center;

  color: #616161;

  opacity: 0.9;
  width: 70%;
  margin: auto auto 35px auto;

}

.modal-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 132.5%;
  /* or 32px */
  width: 55%;
  /* text-align: center; */

  color: #FFFFFF;
  margin-bottom: 32px !important;
}

.subscribe_modal_content .modal-text {
  text-align: center;

}

.download-btn {
  border-radius: 64.6362px;
  font-weight: 400;
  font-size: 10.3468px;
  line-height: 150%;
  /* or 16px */

  display: flex;
  align-items: center;

  color: #FFFFFF;
  padding: 13px 15px;
}

.download-btn.android {
  background: #5F42E2;
  border: 0.646362px solid #AB99FF;
  border-radius: 64.6362px;
  margin-right: 12px;
}

.download-btn.ios {
  background: #1D1C24;
  border: 0.646362px solid #1D1C24;
}

.download-icon {
  margin-right: 7.72px;
}

.subscribe-button {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  border-radius: 5px;
  padding: 9px 24px;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  border: none;
}

.subscribe-input,
.subscribe-input:focus {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  width: 100%;
  background: transparent;
  border: none;

  color: #303030;

  box-shadow: none;
  margin-right: 5px;
  outline: none;

}

.subscribe-input::placeholder {
  color: #9F9F9F;

}

.subscribe-input-wrapper {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 3px 3px 3px 22px;
  /* width: 80%; */
  border: 0.818421px solid #DADADA;

}

.subscribe_bg {
  right: 13px;
  top: 7px;
}

.close-btn-wrapper {
  margin-left: -28px !important;
}


.mobile-search {
  top: 22px;
  right: 10px;
}

.mobile-intent-controller {
  right: 10px;
  top: 145px;


}

.download_bg {
  right: 0;
  bottom: 5px;
}

.download_bg img {
  height: 270px;
  width: 135px;
}

.content-intent-btn.love {
  background: #FF4E4E !important;
}

/* @media(max-width:1400px){} */

@media(max-width:1400px) {
  .loader-wrapper.app-wrapper {
    height: calc(100vh - 103px) !important;

  }

  .explore-player-content__wrapper {
    margin-top: 80px !important;
  }


  .explore-player-content__wrapper .mobile-intent-controller {
    bottom: 145px !important;
    top: unset;
  }

  .app-wrapper {
    /* min-height: calc(100vh - 133px);
     */
    height: unset;
  }

  .mobile-image-container {
    height: 68px;
    width: 68px;
    border-radius: 10px;
    border: 1px solid #181717
  }

  .mobile-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #303030
  }

  .subscribe_modal_content .modal-text,
  .subscribe_modal_content .modal-sub-text {
    width: 100% !important;
  }

  .content-details {

    padding: 0 20px 20px 20px !important;

  }

  .first-section-wrapper {
    border-right: unset;
    border-bottom: 9px solid #181717;
    border-radius: 0;
    height: 75vh;
  }

  .second-section-wrapper {
    height: unset;
    border-radius: 0 0 14px 14px;
  }

  .content-img-wrapper {
    height: calc(60vh - 9px) !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 9px;
  }

  .first-section-wrapper:before {
    border-radius: 15px 15px 0 0;

  }


  /* .app-wrapper {

    height: calc(100vh - 103px);
    overflow: hidden;

  } */

  .get-app-btn {

    font-weight: 400;
    font-size: 12.9123px;
    padding: 9px 29px !important;
  }

  .content-name {
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 15px;
    width: 67%;
  }

  .content-ctrl-center.mb-5 {
    margin-bottom: 1.5em !important;
  }

  #playPause img {
    width: 37px;
    height: 37px;
  }

  .content-author {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 20px */


    color: #FFFFFF;

  }

  .content-author-role {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */


    color: #979797;
  }




  .intent-name {
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    /* identical to box height, or 16px */

    text-align: center;

    color: #979797;

    text-shadow: 0px 4px 28px #000000;
    margin-top: 4px;
  }

  .section-title-wrapper {
    padding: 0px;
    background: unset;
    border-radius: 3px;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */


    color: #FFFFFF;

  }

  .episodes-container {
    border-bottom: none;
    background: #242424;
    border-radius: 5.57118px;
    padding: 17px 23px;
  }

  .episode-content:last-child {
    margin-bottom: 0px !important;
  }



  .mobile-active {
    color: #fff;
  }

  .episode-img-container.empty {
    width: 68px;
    height: 68px;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    border-radius: 6px;
    margin-right: 19px !important;
  }

  .episode-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    /* width: 80%; */
  }

  .episode-content-container {
    width: 70%;

  }
}

@media (max-width:767px) {

  .content-intent-btn {
    background: unset;
    border-radius: 16px;
    padding: 3px 12px;
    margin-right: unset;
  }

  .intent-icon {
    width: 36px;
    height: 36px;
    left: 337px;
    top: 251px;

    background: #2F2F2F;
    border-radius: 50%;
    margin-right: 0px;
  }

  .intent-icon.love,
  .content-intent-btn.love {
    background: #FF4E4E !important;
  }

  .episode-length.active {
    background: #FFFFFF;
  }

  .episodes-container.explore-player__episodes-container {
    height: 100vh;
    overflow: scroll;
    padding-bottom: 110px;
  }
}

@media(max-width:380px) {
  .mobile-download-wrapper {
    padding: 20px 17px !important;
  }
}

.mobile-download-wrapper {
  background: url('./assets/img/left.svg'), url('./assets/img/right.svg'), linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%);
  background-repeat: no-repeat;
  background-position: left 0%, right 100%, center;
  height: 149px;
  border-radius: 14px;
  padding: 20px 23px;
  position: relative;
}

.mobile-download-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 169.5%;
  width: 48%;
  /* or 27px */


  color: #FFFFFF;
}

.mobile-download-bg-img {
  right: -16px;
  top: 0;
}

.scroll-icon-container {
  top: 35%;
  right: 43%;
}

.socialSharePopupMoveIn button[aria-label="Copy link"] {
  background: linear-gradient(86.63deg, #5F42E2 11.49%, #9B42C0 131%) !important;
  color: #fff !important;
}

button[aria-label="Copy link"] span {
  display: none !important;
}

button[aria-label="Close"] {
  background: #000 !important;
  color: #fff !important;

}


/* .socialShareBackdropFadeIn{animation:simpleFade .5s;}
.socialShareBackdropFadeOut{animation:simpleFade .5s reverse;}
.socialSharePopupMoveIn{animation:fadeInUp .5s; }
.socialSharePopupMoveOut{animation:fadeInUp .5s reverse; }
@keyframes simpleFade{0 % :{ opacity: 0 }100%{opacity:1}}
@keyframes fadeInUp{0 % { opacity: 0; transform: translateY(20px) }100%{opacity:1;transform:translateY(0)}} */

.socialShareBackdropFadeIn.mobile-content__wrapper {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0px;
  top: 7em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1400
}

.socialSharePopupMoveIn {
  max-width: 24rem;
  width: 90%;
  background-color: white;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}